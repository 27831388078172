<template>
<div class='container mx-auto bg-orange-900 p-4 mb-6 text-xs md:text-base'>
    
    <font-awesome-icon class='mr-2 text-white w-5 h-5 relative top-0.5' icon="fa-solid fa-triangle-exclamation"  />

    A new version is available. We recommend upgrading <button @click="$emit('refresh')" class='md:float-right bg-orange-600 border border-yellow-300 text-white p-1.5 mt-4 md:-mt-1.5'>Upgrade Now</button>

</div>
</template>

<script>

export default {


    methods: {

    }

}
</script>
