<template>
  <button class="text-white bg-yellow-700 hover:bg-yellow-800 font-medium rounded-sm text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-yellow-600 dark:hover:bg-yellow-700 border-2 border-yellow-700 hover:border-yellow-100 mt-4">{{ label }}</button>
</template>

<script>


export default {
  name: 'SaveButton',

  props: {
    label: { default: 'Save'},
  },



}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
