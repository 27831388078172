<template>
  <div id="app">

    <Navigation v-if="authenticated" />

    <router-view v-else />
    <authenticator-setup />


  </div>
</template>


<script>
  import { mapGetters } from 'vuex'
  import Navigation from '@/components/Navigation.vue';
import EventBus from '@/EventBus';



  export default {


    components: {
      Navigation
    },

    computed: {
      ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user',
        connected: 'connection/connected',
      })
      
    },

    mounted() {

      EventBus.listen('mfa-enabled', data => this.$router.replace({ name: 'Home' }));
      
    }

  }



</script>
