<template>
  <div >
    <v-select 
      class='search-select' 
      :label="itemName" 
      :value="value" 
      @input="changed" 
      :reduce="option => option[itemValue]" 
      :options="options" 
      :placeholder="place_holder" 
      :multiple="multiple_options"
      :disabled="read_only"
      :noDrop="read_only"
      :searchable="is_searchable"
      :clearable="!read_only && clearable"
      style='white-space: normal; vertical-align: top;  line-break: loose; overflow-wrap: anywhere;'

      />
  </div>
</template>

<script>

import 'vue-select/dist/vue-select.css';

export default {

    props: [
        'options',
        'placeholder',
        'itemName',
        'itemValue',
        'value',
        'multiple',
        'readonly',
        'searchable',
        'clearable',
        
    ],

    components: {
    },

    data() {
        return {
            place_holder: this.placeholder ? this.placeholder : 'Select',
            multiple_options: this.multiple ? true : false,
            read_only: this.readonly ? true : false,
            is_searchable: this.searchable || this.searchable == null ? true : false,
            
        }
    },


    watch: {

      readonly: function (newVal, oldVal) {
          this.read_only = !!newVal;
      }

    },
  

    methods: {


        changed(value) {
            
            this.$emit('input', value)            
            
        },
        

    },


}

</script>

<style scoped>

 .style-chooser.vs__dropdown-menu {
    background: red !important;
    border: none;
    color: red !important;
    text-transform: lowercase;
    font-variant: small-caps;
  }

.search-select >>> {
  --vs-controls-color: #ffee00;
  --vs-border-color: #374151;
  --vs-line-height: 2.2;

  --vs-dropdown-bg: #555;
  --vs-dropdown-color: #ff9;
  --vs-dropdown-option-color: #ffffff;

  --vs-selected-bg: #333;
  --vs-selected-color: #ffeebb;


  --vs-search-input-color: #ffeebb;

  --vs-dropdown-option--active-bg: #ff9;
  --vs-dropdown-option--active-color: #000;

  --vs-dropdown-max-height: 400px;
  --vs-disabled-bg: none;

}


</style>