import axios from 'axios'
import router from '../router'
import store from '../store'
import EventBus from '@/EventBus';


export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null,
    project: null,
    roles: [],
    burnableVisibility: 0,
    clientVersion: null,
    upgradeAvailable: false,
    alerts: false,
    alertsImportant: false,

  },

  getters: {
    authenticated (state) {
      return state.authenticated
    },

    user (state) {
      return state.user
    },

    project (state) {
      return state.project
    },

    roles (state) {
      return state.roles
    },

    burnableVisibility (state) {
      return state.burnableVisibility
    },

    clientVersion (state) {
      return state.clientVersion
    },

    upgradeAvailable (state) {
      return state.upgradeAvailable
    },
    alerts (state) {
      return state.alerts
    },
    alertsImportant (state) {
      return state.alertsImportant
    }

  },

  mutations: {
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },

    SET_USER (state, value) {
      state.user = value
    },

    SET_PROJECT (state, value) {
      state.project = value
    },

    SET_ROLES (state, value) {
      state.roles = value
    },

    SET_BURNABLE_VISIBILITY (state, value) {
      state.burnableVisibility = value
    },

    SET_CLIENT_VERSION (state, value) {
      state.clientVersion = value
    },
    SET_UPGRADE_AVAILABLE (state, value) {
      state.upgradeAvailable = value
    },

    SET_FORM_PREFERENCE (state, value) {
      state.user.form_preference = value
    },

    SET_ALERTS (state, value) {
      state.alerts = value
    },

    SET_ALERTS_IMPORTANT (state, value) {
      state.alertsImportant = value
    }


  },


  actions: {
    async signIn ({ dispatch }, form) {

      const pass = form.password;

      await axios.get('/sanctum/csrf-cookie')

      await form.post('/login')
        .then((response) => {


          if (form.initialRegistration) {
            return dispatch('me');  // user will be presented with MFA setup

          } else if (response.two_factor) {
            router.replace({ name: 'SignInChallenge' })


           } else if (response.domain_enforced || (!response.two_factor && !response.opted_out)) {
  
              let match = document.cookie.match(new RegExp("(^| )" + process.env.VUE_APP_XSRF_TOKEN_NAME + "=([^;]+)"));
              let token = match ? match[2] : '';
  
              if (token)
                  axios.defaults.headers.common['X-' + process.env.VUE_APP_XSRF_TOKEN_NAME] = decodeURIComponent(token);     
  
  
              axios.post('/user/confirm-password', { password: pass })
                .then(() => {
                  EventBus.fire('setup-authenticator', { mfaEnabled: false, initialRegistration: true, policyEnforced: response.domain_enforced });
                  router.replace({ name: 'Dashboard' })

              });
 
          

          } else {
            router.replace({ name: 'Dashboard' })
          
            return dispatch('me');
          }

        })

    },

    async signOut ({ commit }) {

      // to avoid interceptor eternal loop
      const uninterceptedAxiosInstance = axios.create();
      await uninterceptedAxiosInstance.post('/logout');

      localStorage.removeItem('name');

      commit('SET_AUTHENTICATED', false)
        
      commit('SET_USER', null)

      commit('SET_UPGRADE_AVAILABLE', null);
      
      commit('SET_CLIENT_VERSION', null);

    },


    me ({ commit }) {
      return axios.get('/api/user').then((response) => {

        commit('SET_AUTHENTICATED', true)

        commit('SET_USER', response.data)

        localStorage.name = response.data.name;

        axios.get('/api/default-project').then((response) => {
            commit('SET_PROJECT', response.data.project);
            commit('SET_ROLES', response.data.roles);
            commit('SET_BURNABLE_VISIBILITY', response.data.burnable_visibility);
        });

          
      }).catch(() => {

        commit('SET_AUTHENTICATED', false)
        
        commit('SET_USER', null)
        
      })
    },


    project ({ commit }) {
      return axios.get('/api/default-project').then((response) => {

        commit('SET_PROJECT', response.data.project);
        commit('SET_ROLES', response.data.roles);
        commit('SET_BURNABLE_VISIBILITY', response.data.burnable_visibility);
          
      }).catch(() => {

        commit('SET_PROJECT', null);
        commit('SET_ROLES', null);
        commit('SET_BURNABLE_VISIBILITY', null);
        
        
      })
    },


  }  
}

axios.interceptors.request.use(function (config) {


     let match = document.cookie.match(new RegExp("(^| )" + process.env.VUE_APP_XSRF_TOKEN_NAME + "=([^;]+)"));
     let token = match ? match[2] : '';

    if (token)
        axios.defaults.headers.common['X-' + process.env.VUE_APP_XSRF_TOKEN_NAME] = decodeURIComponent(token);     

    return config;


}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});



axios.interceptors.response.use(
  (response) => {
    
    if (response.headers['x-client-version'] && store.getters['auth/clientVersion'] != response.headers['x-client-version']) {
      if (store.getters['auth/clientVersion'] !== null) {
        store.commit('auth/SET_UPGRADE_AVAILABLE', true);
       }
       store.commit('auth/SET_CLIENT_VERSION', response.headers['x-client-version']);
     }
  
    
    return response;
  },
  function (error) {

    if (!error.response)

      return Promise.reject('Unknown network error');

    else if (error.response.status === 401 || error.response.status === 419) {
      console.log('Session expired!');
      router.replace({ name: 'Landing' })

//      store.dispatch("auth/signOut");


    }
    return Promise.reject(error.response);
  }
);
