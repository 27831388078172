import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import connection from './connection'

// filters
import scaffoldRequests from './filters/scaffoldRequests'
import inspections from './filters/inspections'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    connection,
    scaffoldRequests,
    inspections,
  }
})
