<template>
  <div class="navigation">

      <main class="flex flex-col h-screen bg-gray-900">
 
      <!-- mobile menu bar -->
 
      <div class="flex flex-1 overflow-y-auto">
        
      <FlashMessage :position="'right bottom'"></FlashMessage>

      <!-- sidebar -->
        <SideBar />

        <div class="flex flex-1 flex-col overflow-x-hidden overflow-y-auto">

          <MainBar />
          <div id="router-view" class="bg-gray-900 text-white overflow-y-auto p-2" :style="opacity">
              <UpdateAvailable v-if="updateExists" @refresh='refreshApp' />
              <UpgradeAvailable v-else-if="upgradeAvailable" />

              <router-view/>
          </div>
      
      </div>

    
   </div>

  
  </main>


  </div>

</template>

<script>

import { mapActions } from 'vuex'
import SideBar from '@/components/SideBar.vue';
import MainBar from '@/components/MainBar.vue';
import UpgradeAvailable from '@/components/UpgradeAvailable.vue';
import UpdateAvailable from '@/components/UpdateAvailable.vue';
import store from '@/store';
import EventBus from '@/EventBus';
import update from '@/mixins/update';

export default {
  name: 'Navigation',

mixins: [
  update,
],

components: {
    SideBar,
    MainBar,
    UpgradeAvailable,
    UpdateAvailable,
  },

  data() {

    return {

      showingMenu: false,

    }

  },

  mounted() {

    EventBus.listen('popup-menu', action => this.showingMenu = action);

  },

  computed: {

    upgradeAvailable() {

       return store.getters['auth/upgradeAvailable'];

    },

    opacity() {

      return this.showingMenu ? 'opacity: 0.1' : '';

    }
    

  },

  methods: {
      ...mapActions({
      signOutAction: 'auth/signOut',
      meAction: 'auth/me',
      }),

      async signOut () {
          await this.signOutAction()

          this.$router.replace({ name: 'Landing' })
      },

      toggleMenu() {
      
        document.querySelector("#sidebar").classList.toggle("-translate-x-full");

      }


}


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



</style>
