export default {
    data: function() {
        return {
            timezones: [
                { name: 'Atlantic', zone: 'AT' },
                { name: 'Central', zone: 'CT' },
                { name: 'Mountain', zone: 'MT' },
                { name: 'Eastern', zone: 'ET' },
                { name: 'Pacific', zone: 'PT' },
              ],

            ianaTimezones: [
                { name: 'Atlantic', zone: 'America/Halifax' },
                { name: 'Central', zone: 'America/Regina' },
                { name: 'Mountain', zone: 'America/Edmonton' },
                { name: 'Eastern', zone: 'America/Toronto' },
                { name: 'Pacific', zone: 'America/Vancouver' },
            ],
        }
    }
}