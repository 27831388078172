<template>

  <div id="grow-area-photo">
    <textarea 
      type="text" 
      :value="value"
      @keyup="$emit('input', $event.target.value);" 
      :maxlength="maxlength" 
      class="bg-transparent border border-gray-700 py-2.5 pl-2 text-yellow-100" 
      placeholder="details..." 
      autocomplete="off"
      id="grow-textarea"
      style='padding-right:55px;'
      >
    </textarea>
      <span class='w-10 h-10 absolute right-2 bottom-1' @click="$emit('photo')">
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill='#FF4' x="0px" y="0px" viewBox="0 0 230.629 230.629" style="enable-background:new 0 0 230.629 230.629;" xml:space="preserve"> <path d="M230.629,59.325H0v150.989h230.629V59.325z M115.314,183.373c-26.814,0-48.553-21.738-48.553-48.554 c0-26.814,21.738-48.553,48.553-48.553s48.555,21.739,48.555,48.553C163.869,161.635,142.129,183.373,115.314,183.373z M88.041,20.315h54.547l17.943,28.93H70.1L88.041,20.315z M144.447,134.819c0,16.089-13.043,29.133-29.133,29.133 c-16.088,0-29.131-13.044-29.131-29.133c0-16.089,13.043-29.132,29.131-29.132C131.404,105.688,144.447,118.73,144.447,134.819z"/> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
      </span>
  </div>

</template>

<script>

export default {

    props: {
      value: {
        required: true,
      },
      maxlength: {
        default: 300
      }
    },

      mounted() {


      const grower = document.querySelector("#grow-area-photo");

      const textarea = grower.querySelector("textarea");
      textarea.addEventListener("input", () => {
        grower.dataset.replicatedValue = textarea.value;
      });

    },

    methods: {

        test() {
        }

    }



}

</script>

<style scoped>

#grow-area-photo {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}
#grow-area-photo::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
#grow-area-photo > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;

  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
#grow-area-photo > textarea,
#grow-area-photo::after {
  /* Identical styling required!! */
  padding: 0.5rem;
  font: inherit;

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}

 
</style>