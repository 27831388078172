import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import components from './components'
import router from './router'
import store from './store'
import axios from 'axios'
import './index.css'
import FlashMessage from '@smartweb/vue-flash-message';
import Form from './forms/Form';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHouse,faTrashCan,faThumbsUp,faThumbsDown,faCamera,faClock,faUser,faDiagramProject,faComment,faArrowsRotate,faArrowLeft,faArrowRight,faLayerGroup,faCalendar,faPlay,faStop,faFile,faPhone,faMobileScreen,faSquarePhone,faTriangleExclamation,faCopy,faPrint,faPaintBrush,faTag,faChevronLeft,faChevronRight,faServer,faFire,faBolt,faSprayCan,faTemperatureFull,faHardHat,faWrench,faKey,faAddressCard,faTags,faCheck,faMapPin,faCalculator,faUserTag,faImages,faCogs,faDollarSign,faSearch,faFilter,faSun,faMoon,faEdit,faLightbulb,faCaretLeft,faCaretRight,faBell } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mapActions } from 'vuex'
import './assets/tailwind.css'
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
import VueClipboard from 'vue-clipboard2';
import VueHtmlToPaper from 'vue-html-to-paper';
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';


Vue.use(VueHtmlToPaper);
Vue.use(VueClipboard)
Vue.use(Donut);
Vue.use(VueGoodTablePlugin);

library.add(faHouse,faTrashCan,faThumbsUp,faThumbsDown,faCamera,faClock,faUser,faDiagramProject,faComment,faArrowsRotate,faArrowLeft,faArrowRight,faLayerGroup,faCalendar,faPlay,faStop,faFile,faPhone,faMobileScreen,faSquarePhone,faTriangleExclamation,faCopy,faPrint,faPaintBrush,faTag,faChevronLeft,faChevronRight,faServer,faFire,faBolt,faSprayCan,faTemperatureFull,faHardHat,faWrench,faKey,faAddressCard,faTags,faCheck,faMapPin,faCalculator,faUserTag,faImages,faCogs,faDollarSign,faSearch,faFilter,faSun,faMoon,faEdit,faLightbulb,faCaretLeft,faCaretRight,faBell)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(FlashMessage, { time: 3000, strategy: 'multiple' });


window.Form = Form;

axios.defaults.withCredentials = true

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API

Vue.config.productionTip = false


new Vue({
  components,
  router,
  store,
  render: h => h(App),
  created () {
    
    window.addEventListener('offline', () => {
      this.setConnected(false)
    })

    window.addEventListener('online', () => {
      this.setConnected(true)
    })
  },

  mounted() {
    console.log(process.env.VUE_APP_ROOT_API)
    console.log(process.env.VUE_APP_XSRF_TOKEN_NAME)
  },

  methods: {

    ...mapActions({
      setConnected: 'connection/setConnected'
    }),

    
  },

}).$mount('#app')





