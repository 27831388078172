<template>
<div>
    <div class="min-w-screen overflow-y-auto py-2 h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" v-if="show">
        <div class="absolute bg-black opacity-90 inset-0 z-0"></div>
        <div class="w-11/12 md:w-4/5 relative mx-auto my-auto rounded-md shadow-lg  bg-gray-900 border-2 border-gray-400">


          <div class="p-4">
            <div class="text-center p-0 md:p-5 flex-auto justify-center">
              
                   


                <div v-if="page == 1">

                    <h1 class="block text-xl md:text-5xl font-bold text-slate-100">Two-factor Authentication</h1>

                    <p v-if="policyEnforced" class="text-md md:text-lg font-medium mt-8 md:mt-6 text-yellow-100">Your domain policy requires you to set up two-factor authentication before proceeding to use this application</p>
                    <p v-else class="text-md md:text-lg font-medium mt-8 md:mt-6 text-yellow-100">We recommend enabling two-factor authentication to increase the security of your account</p>


                    <div class="2xl:container 2xl:mx-auto md:py-5 bg-slate-900">
                    <div class="bg-slate-900 dark:bg-gray-900">
                        <div class="flex flex-col lg:flex-row items-strech justify-center lg:space-x-8">
                        <div class="lg:w-1/2 flex justify-between items-strech bg-transparent  px-2 py-6 md:px-6">
                            <img src="/images/authentication.png" class="w-full h-full bg-slate-900" />
                        </div>
                        <div class="lg:w-1/2 flex flex-col justify-center md:mt-4 mt-0">
                            <h1 class="text-3xl lg:text-4xl font-semibold text-white">Get the app</h1>
                            <p class="text-base leading-normal text-gray-300 dark:text-white mt-2">On your device, start by downloading an authenticator app. You may chose <span class='text-yellow-500'>Google Authenticator</span>, <span class='text-blue-400'>Microsoft Authenticator</span>, <span class='text-red-500'>Authy</span> or <span class='text-green-500'>Duo</span>. After installation, open one of the apps and proceed to the next step.</p>
                            <p class="text-3xl font-medium text-gray-600 dark:text-white mt-8 md:mt-10"></p>
                            <div class="flex items-center flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6 lg:space-x-8 mt-2 md:mt-16">
                                <button class="w-full md:w-3/5 text-base font-medium leading-none text-white uppercase py-6 bg-blue-800 text-white hover:bg-blue-700" @click="nextPage()">Next</button>
                                <button v-if="!policyEnforced" class="w-full md:w-3/5 text-base font-medium leading-none text-white uppercase py-6 bg-gray-600 text-white hover:bg-gray-500" @click="close();">{{ cancelText }}</button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                </div>


                <div v-else-if="page == 2">
                    

                    <div class='md:grid md:grid-cols-2'>
                        <div>
                            <h1 class="block text-xl md:text-2xl font-bold text-slate-100">Two-factor Authentication</h1>

                            <div class='text-gray-200 text-xs md:text-base'>
                                Use your authenticator app to scan the QR code below. If you are unable to scan, the secret key has been provided under the QR code. Clicking on the blue icon will copy the code to your clipboard to paste within the app.

                                When you have scanned or copied the code into the app, enter the Authentication Code in the outlined box.
                            </div>

                            <div class='flex justify-center mt-4'>
                                <div>

                                    <div class='flex justify-center mt-4'>
                                        <div style='encoding="utf-8"' v-html="qrCode"></div>
                                    </div>


                                    <div class="relative flex h-12 w-full flex-row-reverse overflow-clip rounded-sm mt-6">
                                        <input readonly ref="secretKey" :value="secretKey" class="peer w-full rounded-r-lg border-2 border-dashed bg-transparent border-slate-600 px-2 text-yellow-100  focus:outline-none"  />
                                        <label v-clipboard:copy="secretKey" v-clipboard:success="onCopy" class="flex items-center rounded-l-lg border border-transparent bg-blue-800 px-2 font-bold text-white"><font-awesome-icon class='w-5 h-5 cursor-pointer text-white px-4 bg-transparent relative ml-2' icon="fa-solid fa-copy" /></label>
                                    </div>
                                    <div class='text-yellow-400 h-8'><span v-if="copiedToClipboard">Copied to clipboard!</span></div>


                                </div>
                            </div>


                        </div>
                        <div>
                       

                            <div class='flex justify-center mt-8'>
                                <GoogleAuthenticator @input='confirmMfa($event)' />
                            </div>

                            <div class="flex justify-center mt-12">
                                <button v-if="!policyEnforced" class="w-full md:w-1/4 text-base font-medium leading-none text-white uppercase py-6 bg-gray-600 text-white hover:bg-gray-500 md:mt-20" @click="close();">{{ cancelText }}</button>
                            </div>

                        </div>
                    </div>




                </div>

                <div v-else-if="page == 3">

                    <h1 class="block text-xl md:text-5xl font-bold text-slate-100">Two-factor Authentication</h1>

                    <div class="2xl:container 2xl:mx-auto md:py-12 bg-slate-900">
                    <div class="bg-slate-900 dark:bg-gray-900">
                        <div class="flex flex-col lg:flex-row items-strech justify-center lg:space-x-8">
                        <div class="lg:w-1/2 flex justify-between items-strech bg-transparent  px-2 py-6 md:px-6">
                            <svg fill="#44CC44" class='w-full h-full bg-slate-900' viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title>ionicons-v5-s</title><path d="M479.07,111.36a16,16,0,0,0-13.15-14.74c-86.5-15.52-122.61-26.74-203.33-63.2a16,16,0,0,0-13.18,0C168.69,69.88,132.58,81.1,46.08,96.62a16,16,0,0,0-13.15,14.74c-3.85,61.11,4.36,118.05,24.43,169.24A349.47,349.47,0,0,0,129,393.11c53.47,56.73,110.24,81.37,121.07,85.73a16,16,0,0,0,12,0c10.83-4.36,67.6-29,121.07-85.73A349.47,349.47,0,0,0,454.64,280.6C474.71,229.41,482.92,172.47,479.07,111.36Zm-131,75.11-110.8,128A16,16,0,0,1,225.86,320h-.66a16,16,0,0,1-11.2-4.57l-49.2-48.2a16,16,0,1,1,22.4-22.86l37,36.29L323.9,165.53a16,16,0,0,1,24.2,20.94Z"/></svg>
                        </div>
                        <div class="lg:w-1/2 flex flex-col justify-center md:mt-5 mt-0">
                            <h1 class="text-3xl lg:text-4xl font-semibold text-white">Two-factor authentication complete!</h1>
                            <div class="text-base leading-normal text-gray-300 dark:text-white mt-2 px-10">
                                
                                <div><span class='font-bold text-red-500'>Important! </span>  Below is a list of your recovery codes. If you ever lose access to your Authenticator, you will need one of these recovery codes to verify your account. </div>
                                <div class='font-bold text-yellow-200'>Keep these codes in a safe and secure place! </div>


                                <div id='recoveryCodesContainer' class='mt-4 border border-dashed border-slate-500 p-3'>
                                    <ul v-for="(code,index) in recoveryCodes" :key="index">
                                        <li>{{ code }}</li>
                                    </ul>
                                </div>
                                <div class='text-yellow-400 h-4'><span v-if="copiedToClipboard">Copied to clipboard!</span></div>

                                <div class="relative flex h-12 w-full flex-row-reverse overflow-clip rounded-sm">
                                    <button @click="copyRecoveryCodesToClipboard()" class="flex items-center border border-transparent bg-blue-800 px-2 font-bold text-white"><font-awesome-icon class='w-5 h-5 cursor-pointer text-white px-4 bg-transparent relative ml-2' icon="fa-solid fa-copy" /></button>
                                    <button @click="printRecoveryCodes()" class="flex items-center border border-transparent bg-orange-900 px-2 font-bold text-white"><font-awesome-icon class='w-5 h-5 cursor-pointer text-white px-4 bg-transparent relative ml-2' icon="fa-solid fa-print" /></button>
                                </div>

                                
                            </div>

                            <p class="text-3xl font-medium text-gray-600 dark:text-white mt-8 md:mt-10"></p>
                            <div class="flex items-center flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6 lg:space-x-8 mt-2 md:mt-16">
                                <button class="w-full md:w-3/5 text-base font-medium leading-none text-white uppercase py-6 bg-gray-600 text-white hover:bg-gray-500" @click="close();">Done</button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                </div>


            </div>


          </div>
        </div>
    </div>


        <div class="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" v-if="passwordDialog">
            <div class="absolute bg-black opacity-80 inset-0 z-0"></div>
            <div class="w-full w-11/12 relative mx-auto my-auto rounded-xl shadow-lg">


                <div class="w-full max-w-md mx-auto p-6">
                    <div class="mt-7 bg-slate-900  rounded-xl shadow-lg bg-slate-900 text-white border border-slate-500">
                        <div class="p-4 sm:p-7">
                            <div class="text-center">
                            <h1 class="block text-2xl font-bold text-slate-100">Two-factor Authentication</h1>
                            <p class="mt-2 text-sm">
                                <span v-if="!enabled">Let's get started. Please enter your password below</span>
                                <span v-else>Please enter your password below to <span class='text-red-200'>disable</span> 2FA</span>
                            </p>
                            </div>

                            <div class="mt-5">
                                <div class="grid gap-y-4">
                                    <div>
                                        <label for="email" class="block text-sm font-bold ml-1 mb-2 dark:text-white">Password</label>
                                        <div class="relative">
                                            <input v-on:keyup.enter="enabled ? disableMfa() : submitPassword()" ref="password" type="password" maxlength="32" v-model="password" class="py-3 px-4 block bg-slate-700 w-full border-2 border-transparent rounded-md text-xl text-yellow-100 focus:border-yellow-300 shadow-sm" required>
                                        </div>
                                        <div v-show="passwordError" class='text-red-400'>{{ passwordError }}</div>
                                    </div>
                                    <div class='flex cols-2 justify-center items-center gap-2'>
                                        <button v-if="!enabled" @click.prevent="submitPassword" class="py-3 px-4  rounded-md border border-transparent font-semibold bg-yellow-700 text-white hover:bg-yellow-600 focus:outline-none text-sm">Submit</button>
                                        <button v-else @click.prevent="disableMfa" class="py-3 px-4  rounded-md border border-transparent font-semibold bg-yellow-700 text-white hover:bg-yellow-600 focus:outline-none text-sm">Disable</button>
                                        <button @click="closePassword" class="py-3 px-4  rounded-md border border-transparent font-semibold bg-slate-700 text-white hover:bg-slate-600 focus:outline-none text-sm">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>

</template>

<script>

import EventBus from '@/EventBus';
import axios from 'axios';
import GoogleAuthenticator from '@/components/mfa/GoogleAuthenticator.vue';


export default {

    components: {
        GoogleAuthenticator,
    },

    data() {

        return {
            show: false,
            page: 1,
            qrCode: null,
            secretKey: null,
            copiedToClipboard: false,
            recoveryCodes: [],
            passwordDialog: false,
            enabled: false,
            password: '',
            passwordError: null,
            initialRegistration: false,
            policyEnforced: false,


        }

    },

    mounted() {

        EventBus.listen('setup-authenticator', data => this.open(data));

    },

    computed: {

        cancelText() {
            return this.initialRegistration ? 'Opt out' : 'Cancel';
        }


    },


    methods: {

        open(data) {


            if (data.policyEnforced)
                this.policyEnforced = data.policyEnforced;

            if (data.initialRegistration) {

                this.initialRegistration = true;
                this.get2fa();
                this.show = true;

                return;
                
            }

            this.password = '';
            this.passwordError = null;
            this.passwordDialog = true;
            this.enabled = data.mfaEnabled;

            this.$nextTick(() => this.$refs.password.focus());

        },

        close() {

            this.show = false;

            EventBus.fire('close-mfa', {  mfaEnabled: this.enabled });

            if (this.initialRegistration)
                axios.post('/user/two-factor-authentication/opt-out');

        },

        nextPage() {

            this.page++;

        },

        confirmMfa(code) {

            axios.post('/user/confirmed-two-factor-authentication', { code: code })
                .then(response => {

                    this.page = 3;

                    this.enabled = true;

                    this.flashMessage.success({
                        title: 'Success!',
                        icon: '/images/icons/info.svg',
                        message: 'Multi-factor authentication has been enabled!',
                    });

                    EventBus.fire('mfa-enabled');


                }).catch(error => {

                    EventBus.fire('mfa-invalid');

                });


        },

        submitPassword() {

            axios.post('/user/confirm-password', { password: this.password})
                .then(() => {

                    this.password = '';
                    this.passwordError = null;
                    this.passwordDialog = false;
                    this.mfaDialog = true;                    

                    this.get2fa();

                }).catch(error => { this.passwordError = error.data.message })



        },


        get2fa() {

            return axios.post('/user/two-factor-authentication')
                .then(() => {

                    axios.get('/user/two-factor-qr-code')
                        .then(response => this.qrCode = response.data.svg)

                    axios.get('/user/two-factor-secret-key')
                        .then(response => this.secretKey = response.data.secretKey)

                    axios.get('/user/two-factor-recovery-codes')
                        .then(response => this.recoveryCodes = response.data)

                    this.show = true;

                });


        },


    
        onCopy() {

            this.copiedToClipboard = true;

            setTimeout(() => { this.copiedToClipboard = false; }, 3000);

        },

        copyRecoveryCodesToClipboard() {

            let codes = document.getElementById('recoveryCodesContainer');

            if (codes) {

            this.$copyText(codes.innerText)
                .then(() => {

                    this.copiedToClipboard = true;

                    setTimeout(() => { this.copiedToClipboard = false; }, 3000);

                })
            }

        },

        async printRecoveryCodes() {

            await this.$htmlToPaper('recoveryCodesContainer');

        },

        disableMfa() {

            axios.post('/user/confirm-password', { password: this.password})
                .then(() => {

                    this.closePassword();

                    axios.delete('/user/two-factor-authentication')
                        .then(() => {

                            this.enabled = false;

                            this.close();

                            this.flashMessage.info({
                                title: 'Success!',
                                icon: '/images/icons/info.svg',
                                message: 'Multi-factor authentication has been disabled',
                            });

                        });
                }).catch(error => { this.passwordError = error.data.message })



        },
        
        closePassword() {

            this.passwordDialog = false;

            this.password = '';

            this.passwordError = null;

            this.close();

        },



    }

}

</script>
