<template>
<div class="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
    <div class="w-24 h-24 animate-spin rounded-full border-8 border-yellow-200 border-b-transparent"></div>
</div>
</template>

<script>


export default {
  name: 'SimpleSpinner',
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
