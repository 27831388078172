<template>

  <div :id="id" class='grow-area'>
    <textarea 
      type="text" 
      :value="value"
      @keyup="$emit('input', $event.target.value);" 
      :maxlength="maxlength" 
      class="bg-transparent border border-gray-700 py-2.5 pl-2 text-yellow-100" 
      :placeholder="placeholder" 
      autocomplete="off"
      :readonly="readonly"
      id="grow-textarea"
      :rows="rows"
      :ref="reference"
      @blur="$emit('blur')"
      >
    </textarea>
  </div>

</template>

<script>

export default {

    props: {
      value: {
        required: true,
      },
      maxlength: {
        default: 300
      },
      readonly: {
        default: false
      },
      rows: {
        default: 2,
      },
      placeholder: {
        default: 'details...',
      },
      reference: {
        default: 'grow-textarea',
      },

    },


    data() {
      return {
        id: 'grow' + Math.floor(Math.random() * 10000),
      }
    },


    mounted() {

      const grower = document.querySelector('#' + this.id);

      const textarea = grower.querySelector("textarea");
      
      textarea.addEventListener("input", () => {
        grower.dataset.replicatedValue = textarea.value;
      });



      setTimeout(function() {
        grower.dataset.replicatedValue = textarea.value;
      }, 300);

    },

    methods: {

      focus() {
          this.$refs[this.reference].focus();
      }

    }



}

</script>

<style scoped>

.grow-area {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}
.grow-area::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
.grow-area > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;

  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.grow-area > textarea,
.grow-area::after {
  /* Identical styling required!! */
  padding: 0.5rem;
  font: inherit;

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}

 
</style>