<template>
<div>

<vc-donut
    :size="40"
    unit="px"
    :thickness="20"
    background="#1f2937" 
    foreground="grey"
    :total="100"
    :auto-adjust-text-size="false"
    :text="progress.toString()"
    :sections="[{ value: this.value, color: this.donutColor() }]"

    >
    <h1>{{ progress }}</h1>
  </vc-donut>    

</div>    
</template>

<script>
import EventBus from '@/EventBus';

export default {

    props: {
        value: { required: true, type: Number },
        target: { required:true, type: Number },
        id: { required:true }
    },

    data() {

        return {

            progress: this.value ? this.value : 0,


        }

    },

    mounted() {

        EventBus.listen('task-progress-' + this.id, task => { 
            this.progress = task._data.percentDone;
         })

    },



    methods: {

        donutColor() {

            if (this.progress == 100)
            return '#0A0';

            let difference = this.target - this.progress;

            if (difference <= 0)
            return '#0F0';

            else if (difference <= 5)
            return 'yellow';

            else if (difference <= 15)
            return 'orange';

            else
            return 'red';

        }

    }


}

</script>