<template>
  <div class="mainbar">

    <div class="bg-gray-800 text-gray-100 flex justify-between border-b border-gray-700">

      <div class="font-medium text-xs md:text-base col-start-1 col-span-1 cursor-pointer">
          <font-awesome-icon v-show="this.$route.meta.backArrow" class='ml-4 mt-5 h-5 w-4 text-yellow-200' icon="fa-solid fa-arrow-left" @click="$router.go(-1);" />
      </div>

      <div v-if="projectName" class="text-yellow-100 pt-4 pl-6 font-medium col-start-2 col-span-6 text-center">
        <div class='text-white inline mr-1'>Project</div>
        {{ projectName }}
      </div>
      <div v-else class="text-yellow-100 pt-4 pl-6 font-medium mb-4 col-start-2 col-span-6 text-center">
        <div class='text-white inline mr-1'>No Project Selected</div>
      </div>

        <button class="mobile-menu-button py-2 px-4 focus:outline-none focus:bg-gray-700" @click="toggleMenu">
          <client-photo :photo="profilePhoto()" height="10" width="10" class="float-right" />
          <span v-if="alertsImportant" class="absolute flex h-3 w-3 -mt-1 -ml-0.5 lg:hidden">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
          </span>                  
          <span v-else-if="alerts" class="absolute flex h-3 w-3 -mt-1 -ml-0.5 lg:hidden">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-400 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-3 w-3 bg-yellow-500"></span>
          </span>                  

        </button>
      
    </div>

    <!-- <div class="bg-gray-800 text-gray-100 flex justify-between lg:hidden lg:flex border-b border-gray-700 py-3 pl-6 fixed z-40 w-full">
      <div class="font-medium text-xs md:text-base cursor-pointer">
          <font-awesome-icon v-show="this.$route.meta.backArrow" class='h-4 w-4 md:h-6 md:w-6 text-yellow-200' icon="fa-solid fa-arrow-left" @click="$router.go(-1);" />
      </div>

      <div v-if="projectName" class="text-yellow-100 font-medium text-xs md:text-base mr-4">
        <div class='text-white inline mr-1'>Project</div>
        {{ projectName }}
      </div>
      
    </div> -->


  </div>

</template>

<script>

import { mapGetters } from 'vuex'
import ClientPhoto from './ClientPhoto.vue';
import EventBus from '@/EventBus';

export default {
  components: { ClientPhoto },
  name: 'TitleBar',

    data() {

      return {
        showMenu: false,
      }

    },

    computed: {
      ...mapGetters({
        user: 'auth/user',
        project: 'auth/project',
      }),

      projectName() {

          return this.project ? this.project.name : '';

      },

      alerts() {
        return this.$store.getters['auth/alerts'];
      },


      alertsImportant() {
        return this.$store.getters['auth/alertsImportant'];
      }


      
    },

    methods: {

      profilePhoto() {

        return this.$store.getters['auth/user'].avatar;

      },

      popupMenu() {

        this.showMenu = !this.showMenu;

        EventBus.fire('popup-menu', this.showMenu);


      },

      toggleMenu() {
      
        document.querySelector("#sidebar").classList.toggle("-translate-x-full");

      },



      navigateChangeProject() {

        this.showMenu = false;

        EventBus.fire('popup-menu', false);

        this.$router.push({ name: 'ChangeProject' })


      }


    }


}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
