<template>
<div>

    <otp-input
        :isValid="isCodeValid"
        :digits="6"
        @on-complete="onCompleteHandler"
        @on-changed="onChangedHandler"
        @on-paste="onPasteHandler"
        mode="group"
        >
            <template #errorMessage>Invalid code</template>
    </otp-input>

    <div class='text-white flex justify-center text-xl pt-2'>Next code in <span class='ml-1.5 text-yellow-400'>00:{{ seconds }}</span></div>

</div>
</template>

<script>

import  OtpInput  from  "@/components/OtpInput.vue";
import EventBus from '@/EventBus';

export default {

    components: {
        OtpInput,
    },

    data() {

        return {

            timer: null,
            seconds: '00',
            isCodeValid: true,
            code: null,
        }

    },

    mounted() {

        this.timer = setInterval(() => this.countdown(), 1000);

        EventBus.listen('mfa-invalid', data => this.isCodeValid = false);

    },


    methods: {

        countdown() {

            this.seconds = (30 - Math.round(new Date() / 1000) % 30).toString().padStart(2, '0');

        },

	    onCompleteHandler(code){
            this.$emit('input', code);
		},

		onChangedHandler(lastEnteredCode){
		    this.isCodeValid = true;
		},

		onPasteHandler(code){
		},        


    },

    beforeDestroy() {
        clearInterval(this.timer)
    }    
    

}

</script>

