import { render, staticRenderFns } from "./LoadingDivider.vue?vue&type=template&id=38fb2349&scoped=true"
var script = {}
import style0 from "./LoadingDivider.vue?vue&type=style&index=0&id=38fb2349&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38fb2349",
  null
  
)

export default component.exports