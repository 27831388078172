
export default {
  namespaced: true,

  state: {
    per_page: 50,  
    page: 1,
  },

  getters: {
    per_page (state) {
        return state.per_page
    },
    page (state) {
      return state.page;
    },
  

  },

  mutations: {
    SET_PER_PAGE (state, value) {
        state.per_page = value
    },
    SET_PAGE (state, value) {
      state.page = value
    },
  
  },


  actions: {
  

  }  
}
