<template>
<div class="w-full h-screen bg-slate-800">
    <nav class="bg-slate-800 shadow-lg ">
        <div class="md:flex items-center justify-between py-2 px-8 md:px-12">
            <div class="flex justify-between items-center">
               <div class="text-2xl font-bold text-gray-800 md:text-3xl">
                  <img id='header-logo' src="/images/logo.svg" style='width:200px;'/>
               </div>
                <div class="md:hidden">
                    <button type="button" class="block text-gray-800 hover:text-gray-700 focus:text-gray-700 focus:outline-none">
                        <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
                            <path class="hidden" d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z"/>
                            <path d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </nav>
    <div class="flex bg-slate-900" style="height:560px;">
        <div class="flex items-center text-center lg:text-left px-8 md:px-12 lg:w-2/3 ">
            <div>
                <h2 class="text-3xl font-semibold text-gray-300 md:text-4xl">Invitation required</h2>
                <p class="mt-2 text-sm text-gray-400 md:text-base">The Skytrax experience begins with an invitation. If you have been extended an invitation and need technical assistance, please send us a message and we'll help right away! </p>
                <div class="flex justify-center lg:justify-start mt-6">
                    <a class="px-4 py-3 bg-gray-700 text-gray-200 text-xs font-semibold rounded hover:bg-gray-600" href="/signin">Return to Login</a>
                    <a class="mx-4 px-4 py-3 bg-yellow-700 text-white text-xs font-semibold rounded hover:bg-yellow-600" :href="assistance()">Ask for assistance</a>
                </div>
            </div>
        </div>
        <div class="hidden lg:block lg:w-1/3" style="clip-path:polygon(10% 0, 100% 0%, 100% 100%, 0 100%)">
            <div class="h-full object-cover" style="background-image: url(/images/scaffolding.jpg)">
                <div class="h-full bg-black opacity-25"></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

export default {

    methods: {

        assistance() {
            return 'mailto:' + process.env.VUE_APP_SUPPORT_EMAIL + '?subject=Invitation required assistance';
        }

    }

}

</script>
