<template>
  <div class="relative flex min-h-screen flex-col justify-center overflow-hidden md:bg-slate-800 bg-slate-900 py-12">
    <div class="relative bg-slate-900 px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl">
      <div class="mx-auto flex w-full max-w-md flex-col space-y-16">
        <div class="flex flex-col items-center justify-center text-center space-y-2">
          <div class="font-semibold text-3xl text-white">
            <p>Two-factor Authentication</p>
          </div>
          <div class="flex flex-row text-sm font-medium text-gray-400">
            <p>We have sent a code to your Authenticator app</p>
          </div>
        </div>

        <div>
            <div class="flex flex-col space-y-16">
              <div class='flex justify-center'>
                  <Google-Authenticator @input='attempt($event)' class='mt-8' />
              </div>


              <div class="flex flex-col space-y-5">

                <div class="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                  <p>Don't have a code? See our </p> <button class="flex flex-row items-center text-blue-400 hover:underline">recovery options</button>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoogleAuthenticator from '../components/mfa/GoogleAuthenticator.vue';
import axios from 'axios';
import { mapActions } from 'vuex'
import EventBus from '@/EventBus';

  export default {
    name: 'SignIn',

    components: { GoogleAuthenticator },

    data() {

        return {
            code: null,
            error: false,
        }

    },


    methods: {

        ...mapActions({
            signIn: 'auth/me'
        }),        

        attempt(code) {

            axios.post('/two-factor-challenge', { code: code })
                .then(response => {

                    this.$router.replace({ name: 'Home' })
          
                    this.$store.dispatch("auth/me");

                }).catch(error => {

                    EventBus.fire('mfa-invalid');


                });


        }

    }


  }
</script>