<template>
<div class="bg-slate-900">

    <FlashMessage :position="'right bottom'"></FlashMessage>

    <div class='container-invite' @click="cancelPresentation">
        <main v-if="presentation">
            <header class='text-slate-300'>

                <div class="px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
                    <!--Left Col-->
                    <div class="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
                    <img class="w-full md:w-4/5  z-40" src="/images/logo.svg" />


                    </div>
                    <!--Right Col-->
                    <div class="w-full md:w-3/5 py-6 text-center">

                    <h1 class="my-4 text-5xl font-bold leading-tight">
                    </h1>
                    <p class="leading-normal text-3xl md:text-4xl mb-8">
                        Our Premium Service
                    </p>
                    <p class="leading-normal text-xl mb-8 text-gray-200">
                        We are constantly evolving with new services and cutting edge technology to meet our <span class='font-bold'>customer's needs</span>
                    </p>

                    
                    </div>
                </div>
               
            </header>

            <img class='w-full p-6 mx-auto' src="/images/scaffolding.jpg" alt="" />


            <section>
               
            <div class='text-slate-300 text-2xl'> Project Management</div>
            <p>Our proprietary Project Management program, Skytrax, incorporates the latest and greatest technologies for planning, design, resource management, time capture, tracking, and reporting capabilities in real-time, allowing Skyway to greatly improve its control on performance management</p>
            </section>

            <section>

            <div class='text-slate-300 text-2xl'> Our Mission</div>
            <p>To provide industry leading and cutting edge technology to enhance our ability to contribute to our customers’ success. We manage the most complex projects and successfully achieve target results from inception to completion of the project lifecycle, rolling it back to our clients with complete accountability</p>
            </section>

            <img class='mt-12' src="/images/home-screen.jpg" alt="" />

            <section>
                <img src="/images/request-card.jpg" alt="" />
            </section>

            <section>
                <img src="/images/approval-card.jpg" alt="" />
            </section>

            <img class="w-full p-6 mt-10 mx-auto" src="/images/landing-page.jpg" alt="" />

        </main>
  </div>

    <div v-if="!presentation && !verified" class='text-white h-screen flex flex-col h-screen overflow-y-auto'>

        <div class="bg-slate-900 absolute top-0 left-0 bg-gradient-to-b from-slate-900 via-slate-800 to-slate-700 bottom-0 leading-5 h-full w-full overflow-hidden"></div>
        <div class="relative   min-h-screen  lg:flex lg:flex-row  justify-center bg-transparent rounded-3xl shadow-xl overflow-y-auto">
            <div class="xl:flex-col xl:flex  self-center lg:px-14 lg:w-1/2  z-10">
                <div class="self-start flex flex-col  text-gray-300 ">

                    <div class="text-justify relative mx-auto ml-12">
                    <img class="w-4/5 sm:w-5/6 md:w-3/4 lg:w-4/5 z-50" src="/images/logo.svg" />
                    </div>

                    <div class="lg:px-0 text-sm text-slate-400 w-4/5 text-justify sm:w-3/5 lg:w-4/5 relative mx-auto p-4 mt-6 lg:mt-0">You are about to embark on a unique experience within the industry. Please provide your email and invitation code, and we'll have you interacting with your project within seconds.</div>
                </div>
            </div>
            <div class="flex justify-center self-center mt-4 lg:mt-0 z-10 mt-10 md:mt-6 lg:mt-0 mb-16">
                <div class="p-8 lg:p-12 bg-slate-900 mx-auto rounded-3xl w-11/12 sm:w-3/5 lg:w-4/5 ">
                    <div class="mb-6">
                        <h3 class="font-semibold text-2xl text-gray-300">Welcome to Skytrax </h3>
                        <p class="text-gray-400">Provide your email address and the passcode you received within your invitation email</p>
                    </div>
                    <div class="space-y-6">
                        <div class="">
                            <input ref="email" v-model="email" class="w-full text-sm  px-4 py-3 bg-slate-800 text-yellow-200  border  border-gray-200 rounded-sm focus:outline-none focus:border-yellow-200" type="" placeholder="Email" maxlength=120>
                    </div>


                            <div class="relative">
                                <input v-model="passcode" placeholder="Passcode" class="w-full text-sm  px-4 py-3 bg-slate-800 text-yellow-200  border  border-gray-200 rounded-sm focus:outline-none focus:border-yellow-200" maxlength="12">
                                <div class="flex items-center absolute inset-y-0 right-0 mr-3  text-sm leading-5">
                                </div>
                            </div>
                            <div class='h-8 text-red-400'>
                                {{ error }}
                            </div>


                            <div>
                                <button @click="verify" class="w-full flex justify-center bg-yellow-700 hover:bg-yellow-600 text-gray-100 p-3 rounded tracking-wide font-semibold mt-10 cursor-pointer transition ease-in duration-500">Register</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            
	    </div>

    <div v-if="!presentation && verified && !confirmed" class='text-white h-screen flex flex-col h-full overflow-y-auto min-h-screen'>


      <div class="bg-slate-900 mx-auto items-center justify-center mt-12 flex flex-col w-full">
          <div class="bg-slate-800 p-4 rounded-lg shadow-lg max-w-3xl w-full">
              <div class="flex items-center space-x-4 mb-6">
                  <ClientPhoto :photo="null" height="12" width="12" />
                  <div class="text-xl font-semibold relative">Profile Review</div>
              </div>
              <div class="space-y-4 bg-slate-900 px-3 pt-2 pb-8 rounded">
                  <p class="text-sm text-gray-100 mb-2">
                    <font-awesome-icon class='w-4 h-4 bg-transparent text-yellow-400 rounded-full relative mr-2' icon="fa-solid fa-key" />
                    Update password for enhanced account security.
                  </p>

                  <div>
                      <label for="password" class="text-sm font-medium text-gray-100 block mb-1">New Password *</label>
                      <input type="password" v-model="form.password" class="bg-[#0f1727] text-yellow-100 block w-full border border-slate-700 bg-slate-900 p-2 rounded-sm shadow-sm" maxlength="32" required>
                        <span class='text-red-300'>{{ form.errors.get('password') }}</span>
                  </div>
                  <div>
                      <label for="confirmPassword" class="text-sm font-medium text-gray-100 block mb-1">Confirm New Password *</label>
                      <input type="password" v-model="form.password_confirmation" class="bg-[#0f1727] text-yellow-100 block w-full border border-slate-700 bg-slate-900 p-2 rounded-sm shadow-sm" maxlength="32" required>
                  </div>
              </div>
          </div>

          <div class="bg-slate-800 p-4 rounded-lg shadow-lg max-w-3xl w-full mt-4">
              <div class="space-y-4 bg-slate-900 px-3 pt-2 pb-8 rounded">
                  <p class="text-sm text-gray-100 mb-2">
                    <font-awesome-icon class='w-5 h-4 bg-transparent text-yellow-400 rounded-full relative mr-2' icon="fa-solid fa-address-card" />
                    Let's review your personal information
                  </p>

                  <div class='lg:flex lg:gap-4 space-y-5 lg:space-y-0'>
                    <div class='w-full'>
                        <label for="first_name" class="text-sm font-medium text-gray-100 block mb-1 w-full">First Name *</label>
                        <input type="first_name" v-model="form.first_name" class="bg-[#0f1727] text-yellow-100 block w-full border border-slate-700 bg-slate-900 p-2 rounded-sm shadow-sm" maxlength="32" required>
                        <span class='text-red-300'>{{ form.errors.get('first_name') }}</span>
                    </div>
                    <div class='w-full'>
                        <label for="last_name" class="text-sm font-medium text-gray-100 block mb-1 w-full">Last Name *</label>
                        <input type="last_name" v-model="form.last_name" class="bg-[#0f1727] text-yellow-100 block w-full border border-slate-700 bg-slate-900 p-2 rounded-sm shadow-sm" maxlength="32" required>
                        <span class='text-red-300'>{{ form.errors.get('last_name') }}</span>
                    </div>
                  </div>

                  <div class='lg:flex lg:gap-4 space-y-5 lg:space-y-0'>
                    <div class='w-full'>
                        <label for="job_title" class="text-sm font-medium text-gray-100 block mb-1 w-full">Job Title *</label>
                        <input type="job_title" v-model="form.job_title" class="bg-[#0f1727] text-yellow-100 block w-full border border-slate-700 bg-slate-900 p-2 rounded-sm shadow-sm" maxlength="32" required>
                        <span class='text-red-300'>{{ form.errors.get('job_title') }}</span>
                    </div>
                  </div>


                  <div class='lg:flex lg:gap-4 space-y-5 lg:space-y-0'>
                    <div class='w-full'>
                        <label for="business_phone" class="text-sm font-medium text-gray-100 block mb-1 w-full">Business Phone #</label>
                        <input type="business_phone" v-model="form.business_phone" class="bg-[#0f1727] text-yellow-100 block w-full border border-slate-700 bg-slate-900 p-2 rounded-sm shadow-sm" maxlength="16" required>
                        <span class='text-red-300'>{{ form.errors.get('business_phone') }}</span>
                    </div>

                    <div class='w-full'>
                        <label for="mobile_phone" class="text-sm font-medium text-gray-100 block mb-1 w-full">Mobile Phone #</label>
                        <input type="mobile_phone" v-model="form.mobile_phone" class="bg-[#0f1727] text-yellow-100 block w-full border border-slate-700 bg-slate-900 p-2 rounded-sm shadow-sm" maxlength="16" required>
                        <span class='text-red-300'>{{ form.errors.get('mobile_phone') }}</span>
                    </div>
                  </div>


                  <div class='lg:flex lg:gap-4 space-y-5 lg:space-y-0'>
                    <div class='w-full'>
                        <label for="timezone" class="text-sm font-medium text-gray-100 block mb-1 w-full">Timezone *</label>
                        <search-select id="timezone" :options="timezones" v-model="form.timezone" v-on:input="form.errors.clear('timezone')" item-name="name" item-value="zone"></search-select>
                        <span class='text-red-300'>{{ form.errors.get('timezone') }}</span>
                    </div>

                    <div class='w-full'>
                        <label for="discipline_id" class="text-sm font-medium text-gray-100 block mb-1 w-full">Default Discipline</label>
                        <search-select id="discipline_id" :options="disciplines" v-model="form.discipline_id" v-on:input="form.errors.clear('discipline_id')" item-name="name" item-value="id" ></search-select>
                        <span class='text-red-300'>{{ form.errors.get('discipline_id') }}</span>
                    </div>
                  </div>
              </div>
          </div>




          <div class="bg-slate-800 p-4 rounded-lg shadow-lg max-w-3xl w-full mb-20 mt-4">
              <div class="space-y-4 bg-slate-900 px-3 pt-2 pb-8 rounded">
                  <p class="text-sm text-gray-100 mb-2">
                    <font-awesome-icon class='w-5 h-4 bg-transparent text-yellow-400 rounded-full relative mr-2' icon="fa-solid fa-tags" />
                    Project Assignment
                  </p>

                  <div v-if="!otherInvitations.length">You will be automatically assigned to the Project below</div>
                  <div v-else class='text-slate-300'>We found other Projects that you have been invited. You may select a Project below to serve as your default. You may always change this later on within your profile settings. </div>

                  <div class='grid grid-cols-1 lg:grid-cols-2 gap-3'>

                    <div :class='projectCardStyles(invitation)' @click="form.default_project_id = invitation.project.id">
                        <div>Project #<span class='text-slate-300'>{{ invitation.project.external_id }}</span></div>
                        <div class='text-green-100'>{{ invitation.project.name }}</div>
                    </div>

                    <div :class='projectCardStyles(inv)' v-for="inv in otherInvitations" :key="inv.id" @click="form.default_project_id = inv.project.id">
                        <div>Project #<span class='text-slate-300'>{{ inv.project.external_id }}</span></div>
                        <div class='text-green-100'>{{ inv.project.name }}</div>
                        
                    </div>
                  </div>

                 

              </div>

              <div class=''>
                <div @click="confirm" class='p-2 bg-green-800 hover:bg-green-700 text-white w-48 rounded-sm text-center cursor-pointer mt-12 mb-4 border-green-800 hover:border-green-600 border'>Confirm Information</div>
              </div>

          </div>




      </div>    


            
    </div>


  </div>


</template>


<script>

import { mapActions } from 'vuex'
import axios from 'axios';
import ClientPhoto from '@/components/ClientPhoto.vue';
import Form from '@/forms/Form'
import Timezones from '@/mixins/timezones';
import EventBus from '@/EventBus';


export default {

    components: {
        ClientPhoto,
      },

    mixins: [Timezones],

    data() {

        return {

            presentation: true,
            verified: false,
            confirmed: false,
            email: '',
            passcode: '',
            error: '',
            invitation: null,
            otherInvitations: [],
            disciplines: [],

            presentationTimeout: null,
            emailFocusTimeout: null,
            onscrollTimeout: null,

            form: new Form({
               password: '',
               password_confirmation: '',

               first_name: '',
               last_name: '',
               job_title: '',
               discipline_id: null,
               business_phone: null,
               mobile_phone: null,
               timezone: null,

               email: null,
               pass_code: null,
               default_project_id: null,
               
            }),
        
            
        }
        
    },

    mounted() {

        window.onscroll = function () { window.scrollTo(0, 0); };

        this.presentationTimeout = setTimeout(fn => this.presentation = false, 10000);

        this.emailFocusTimeout = setTimeout(fn => this.$refs.email.focus(), 10500);

        this.onscrollTimeout = setTimeout(fn => window.onscroll = null, 11000);


        if (this.$route.query.invitation)
            this.fetchInvitation();

        if (this.$route.query.reference)
            this.passcode = this.$route.query.reference;

        this.fetchDisciplines();

        EventBus.listen('close-mfa', event => { 
            this.$router.replace({ name: 'Home' })

         });

      
                

    },

    methods: {

        ...mapActions({
          signIn: 'auth/signIn'
        }),


        cancelPresentation() {

          clearTimeout(this.presentationTimeout);
          clearTimeout(this.emailFocusTimeout);
          clearTimeout(this.onscrollTimeout);

          this.presentation = false;
          this.$nextTick(() => this.$refs.email.focus());
          window.onscroll = null;
         

        },


        verify() {

            if (!this.email) {

              this.error = 'Your email address is required';

            } else if (!this.passcode) {

              this.error = 'Your invitation passcode is required';

            } else {

              axios.post(`/invitation/verify`, { email: this.email, pass_code: this.passcode })
                  .then(response => {
                      if (response.data.success) {

                        this.invitation = response.data.invitation;
                        this.otherInvitations = response.data.other_invitations;
                        this.form.default_project_id = this.invitation.project_id;
                        this.form.first_name = this.invitation.first_name;
                        this.form.last_name = this.invitation.last_name;
                        this.verified = true;
                        
                      } else {

                         this.error = 'Invalid email / passcode combination';

                      }

                  }).catch(error => {

                    this.flashMessage.error({
                        title: 'An Error has occured!',
                        icon: '/images/icons/error.svg',
                        message: error.message,
                    });

                });

            }
           

        },


        confirm() {

          this.form.email = this.email;
          this.form.pass_code = this.passcode;

          const email = this.email;
          const password = this.form.password;

          this.form.post('/invitation/accept')
            .then(response => {

                if (response.sso_enforced) {
                      this.$router.replace({ name: 'SignIn', query: { sso: 1 } }, )
                      return;
                }


                this.flashMessage.success({
                    title: 'Success!',
                    icon: '/images/icons/success.svg',
                    message: 'Profile information has been confirmed',
                });

                this.confirmed = true;

                axios.get('/sanctum/csrf-cookie')
                  .then(() => {

                    axios.post('/login', {
                      email: email,
                      password: password,
                    }).then((data) => {

                      let match = document.cookie.match(new RegExp("(^| )" + process.env.VUE_APP_XSRF_TOKEN_NAME + "=([^;]+)"));
                      let token = match ? match[2] : '';

                      if (token)
                          axios.defaults.headers.common['X-' + process.env.VUE_APP_XSRF_TOKEN_NAME] = decodeURIComponent(token);     

                      axios.post('/user/confirm-password', { password: password})
                        .then(() => {
                            EventBus.fire('setup-authenticator', { mfaEnabled: false, initialRegistration: true, policyEnforced: data.data.domain_enforced });
                        });

                    });

                  });


            }).catch(error => {
              
              this.flashMessage.error({
                  title: 'An Error has occured!',
                  icon: '/images/icons/error.svg',
                  message: error.data.message,
              });

          });


        },

        fetchInvitation() {

            let invitationId = this.$route.query.invitation;
            let passcode = this.$route.query.reference;

            if (!invitationId || !passcode) return;

            axios.get(`/invitation/${invitationId}/reference/${passcode}/email`)
                .then(response => this.email = response.data.email);

        },

        fetchDisciplines() {

          axios.get('/disciplines')
            .then(response => this.disciplines = response.data.data);

        },

        projectCardStyles(invitation) {

          let styles = "basis-1/2 border rounded-sm p-3 bg-[#203047] text-white cursor-pointer hover:border-yellow-200";

          if (invitation.project.id === this.form.default_project_id)
            styles += " border-green-300";

          else
            styles += " border-slate-700";

          return styles;
        }

    }

  }



</script>

<style scoped>
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 20px;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  position: relative;
}

body {
  background: #000;
  color: #fff;
  height: auto;
  min-height: 100%;
  overflow: hidden;
}

main {
  background: #111;
  border: solid 1px #222;
  padding: 2rem;
  max-width: 100%;
  width: 960px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  height:3000px;
}
main > * {
  grid-column: 1/-1;
}

img {
  max-width: 100%;
}

p {
  line-height: 1.8;
  margin: 1rem 0;
  color: #9ea6b8;
}


section {
  grid-column: auto;
}

header {
  display: grid;
  align-content: center;
  grid-column: 1/-1;
}


/* ---------------------------------- */
.container-invite {
  perspective: 1200px;
  transform-style: preserve-3d;
  -webkit-animation: cinematic-camera 10s cubic-bezier(0.6, 0, 0.4, 1) both;
          animation: cinematic-camera 10s cubic-bezier(0.6, 0, 0.4, 1) both;
}
@-webkit-keyframes cinematic-camera {
  from {
    perspective-origin: 60% 40%;
  }
  to {
    perspective-origin: 40% 60%;
  }
  /* 
    Move the fading to the containing element as to not break inside 3D transforms. 
    See: https://css-tricks.com/things-watch-working-css-3d/
  */
  from, to {
    opacity: 0;
  }
  25%, 75% {
    opacity: 1;
  }
}
@keyframes cinematic-camera {
  from {
    perspective-origin: 60% 40%;
  }
  to {
    perspective-origin: 40% 60%;
  }
  /* 
    Move the fading to the containing element as to not break inside 3D transforms. 
    See: https://css-tricks.com/things-watch-working-css-3d/
  */
  from, to {
    opacity: 0;
  }
  25%, 75% {
    opacity: 1;
  }
}
.container-invite:after {
  content: "";
  background: linear-gradient(to bottom, #000, #0000 20%, #0000 80%, #000);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

main {
  transform-origin: top center;
  transform-style: preserve-3d;
  -webkit-animation: inherit;
          animation: inherit;
  -webkit-animation-name: cinematic;
          animation-name: cinematic;
}
main > img {
  display: block;
  transform-style: preserve-3d;
  -webkit-animation: inherit;
          animation: inherit;
  -webkit-animation-name: image-pop;
          animation-name: image-pop;
}
@-webkit-keyframes image-pop {
  70%, 100% {
    transform: translate3d(0, 0, 60px);
  }
}
@keyframes image-pop {
  70%, 100% {
    transform: translate3d(0, 0, 60px);
  }
}
main > img:last-of-type {
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}
@-webkit-keyframes cinematic {
  from {
    transform: translateZ(-200px) rotateY(30deg) translateY(50vh);
  }
  to {
    transform: translateZ(-100px) rotateY(-30deg) translateY(-100%) translateY(50vh);
  }
}
@keyframes cinematic {
  from {
    transform: translateZ(-200px) rotateY(30deg) translateY(50vh);
  }
  to {
    transform: translateZ(-100px) rotateY(-30deg) translateY(-100%) translateY(50vh);
  }
}

::v-deep
.vs__search, .vs__search:focus {
    background: #0f1727 !important;
}

</style>
