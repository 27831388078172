
import axios from 'axios'

const Errors = require('./Errors');

export default class Form {

    constructor(data) {

        this.originalData = data;

        for (let field in data)
            this[field] = data[field];

        this.errors = new Errors();

        this.files = [];

    }

    reset() {

        for (let field in this.originalData) {

            this[field] = this.originalData[field];

        }

        this.files = [];

        this.errors.clear();


    }

    data() {


        if (this.files.length)
            return this.encodedData();

        return this.plainData();

    }


    // Use FormData multipart to include files, while encoding other fields in JSON
    encodedData() {

        let data = new FormData();

        for (let property in this.originalData) {

            if (this[property] instanceof Array)
                data.append(property, JSON.stringify(this[property]))

                // check if property is true to avoid sending 'null' strings
            else if (property in this && this[property] !== null)
                data.append(property, this[property]);
                
        }

        for (var i = 0; i < this.files.length; i++) {
            let file = this.files[i];
            data.append('files[' + i + ']', file);
        }

        return data;

    }


    // not file attachments - no encoding necessary
    plainData() {

        let data = {};

        for (let property in this.originalData) {

            data[property] = this[property];

        }

        return data;


    }

    post(url, headers = {}) {

        return this.submit('post', url, headers);

    }

    patch(url) {

        return this.submit('patch', url);

    }


    submit(requestType, url, headers = {}) {
       
        return new Promise((resolve, reject) => {
            
            axios[requestType](url, this.data(), headers)
                .then(response => {

                    this.onSuccess();

                    resolve(response.data);

                })
                .catch(error => {

                    if (error.data && error.data.errors)
                        this.onFail(error.data.errors);

                    reject(error);


  
                });
    

        });



    }

    onSuccess() {

        this.reset();


    }


    onFail(errors) {

        this.errors.record(errors);    

    }

}


//module.exports = Form;