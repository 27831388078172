import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from '../views/Landing.vue'
import Invitation from '../views/Invitation.vue'
import InvitationRequired from '../views/InvitationRequired.vue'
import NotFound from '../views/NotFound.vue'
import SignIn from '../views/SignIn.vue'
import SignInChallenge from '../views/SignInChallenge.vue'
import store from '../store'


Vue.use(VueRouter)

const routes = [

  {

    path: '*',
    component: NotFound

  },

  {
    path: '/',
    name: 'Landing',
    component: Landing
  },


  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn
  },  

  {
    path: '/signin-challenge',
    name: 'SignInChallenge',
    component: SignInChallenge,

  },  


  {
    path: '/invitation',
    name: 'Invitation',
    component: Invitation
  },


  {
    path: '/invitation-required',
    name: 'InvitationRequired',
    component: InvitationRequired
  },



  {
    path: '/home',
    name: 'Home',
    meta: {
      requiresAuth: true
    },

    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')

  },  


  {
    path: '/change-project',
    name: 'ChangeProject',
    meta: {
      requiresAuth: true,
      backArrow: true,
    },

    component: () => import(/* webpackChunkName: "change-project" */ '../views/ChangeProject.vue')

    
  },


  {
    path: '/scaffold-request',
    name: 'ScaffoldRequest',
    meta: {
      requiresAuth: true,
      backArrow: true,

    },

    component: () => import(/* webpackChunkName: "scaffold-requests" */ '../views/ScaffoldRequest.vue')
  },

  {
    path: '/scaffold-request/:project/:id',
    name: 'ShowScaffoldRequest',
    meta: {
      requiresAuth: true,
      backArrow: true,
    },

    component: () => import(/* webpackChunkName: "scaffold-requests" */ '../views/ShowScaffoldRequest.vue')
  },



  {
    path: '/my-requests',
    name: 'MyRequests',
    meta: {
      requiresAuth: true,
      backArrow: true,
    },

    component: () => import(/* webpackChunkName: "my-requests" */ '../views/MyRequests.vue')
  },


  {
    path: '/schedule',
    name: 'Schedule',
    meta: {
      requiresAuth: true,
      backArrow: true,
    },

    component: () => import(/* webpackChunkName: "schedule" */ '../views/Scheduling.vue')
  },


  {
    path: '/reporting',
    name: 'Reporting',
    meta: {
      requiresAuth: true,
      backArrow: true,
    },

    component: () => import(/* webpackChunkName: "reporting" */ '../views/Reporting.vue')
  },

  {
    path: '/purchase-orders',
    name: 'PurchaseOrders',
    meta: {
      requiresAuth: true,
      backArrow: true,
    },

    component: () => import(/* webpackChunkName: "purchase-orders" */ '../views/PurchaseOrders.vue')
  },

  {
    path: '/inspectionList',
    name: 'InspectionList',
    meta: {
      requiresAuth: true,
      backArrow: true,
    },

    component: () => import(/* webpackChunkName: "inspections" */ '../views/InspectionList.vue')
  },


  {
    path: '/inspections/:sequence',
    name: 'TagInspections',
    meta: {
      requiresAuth: true,
      backArrow: true,
    },

    component: () => import(/* webpackChunkName: "inspections" */ '../views/TagInspections.vue')
  },


  {
    path: '/scan-tag',
    name: 'ScanTag',
    meta: {
      requiresAuth: true,
      backArrow: true,
    },

    component: () => import(/* webpackChunkName: "scan-tag" */ '../views/ScanTag.vue')
  },

 

  {
    path: '/profile',
    name: 'Profile',
    meta: {
      requiresAuth: true,
      backArrow: true,
    },
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')

  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  const authUser = store.getters["auth/user"];
  const reqAuth = to.matched.some((record) => record.meta.requiresAuth);
  const signin = { path: "/signin", query: { redirect: to.fullPath } };
  const home = { path: "/home", query: { redirect: to.fullPath } };

  const routerView = document.getElementById('router-view');

  if (to.path == '/signin-challenge' && from.path != '/signin')
    return next(signin);
  

  if (routerView)
    routerView.scrollTop = 0;


  if (to.path == '/') {

    const preAuthRoute = localStorage.getItem('preAuthRouteTrimble');
    const searchParams = new URLSearchParams(window.location.search);

    const code = searchParams.has(`code`) ? searchParams.get('code') : null;
    const projectid = searchParams.has(`projectid`) ? searchParams.get('projectid') : null;
    const viewid = searchParams.has(`viewid`) ? searchParams.get('viewid') : null;

    Promise.all([store.dispatch("auth/me"), store.dispatch("auth/project")])
        .then(() => {
      if (store.getters["auth/user"] && preAuthRoute && code) {
        localStorage.removeItem('preAuthRouteTrimble');
        localStorage.setItem('code', code);
        localStorage.setItem('projectid', projectid);
        localStorage.setItem('viewid', viewid);
        return next({path: preAuthRoute, query: {tab : 4}, replace:true});
      }
      else if (store.getters["auth/user"]) {
        return next(home);
      }
      
    });

  }


  if (reqAuth && !authUser) {

    Promise.all([store.dispatch("auth/me"), store.dispatch("auth/project")])
        .then(() => {
      if (!store.getters["auth/user"]) {
        next(signin);
      } else {
        next();
      }
    });
  } else {
    next(); // make sure to always call next()!
  }
});


export default router


