<template>

  <div class="leading-normal tracking-normal text-white gradient" style="font-family: 'Source Sans Pro', sans-serif;">
    
    <!--Nav-->
    <nav id="header" class="fixed w-full z-50 top-0 text-white">
      <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
        <div class="pl-4 flex items-center">
          <a class="toggleColor text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl" href="#">
          <img id='header-logo' class="z-50 hidden" src="/images/logo.svg" style='width:200px;'/>

          </a>
        </div>
                <div class="block sm:hidden pr-4">
          <button id="nav-toggle" class="flex items-center p-1 text-yellow-100 hover:text-yellow-200 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
            <svg class="h-6 w-6" fill='#fff' viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div class="w-full  sm:flex sm:items-center sm:w-auto hidden mt-2 sm:mt-0 bg-gray-900 sm:bg-transparent text-black px-4 py-8 sm:p-0 z-20" id="nav-content">
          <router-link
            to='/signin'
            id="navAction"
            class="mx-auto sm:mx-0 hover:underline bg-yellow-300 border border-yellow-100 text-gray-900 font-bold rounded-md mt-4 sm:mt-0 py-4 px-8 shadow focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
          >
            Log in
          </router-link>

          <ul class="list-reset sm:flex justify-end flex-1 items-center">
            <li class="ml-4 mt-8 sm:mt-0">
              <a class="toggleColor inline-block text-yellow-300 sm:text-black no-underline hover:text-800 hover:text-underline py-2 px-4" href="/invitation">Register</a>
            </li>
          </ul>
        </div>
      </div>
      <hr class="border-b border-gray-100 opacity-25 my-0 py-0" />
    </nav>
    <!--Hero-->
    <div class="pt-24">
      <UpdateAvailable v-if="updateExists" @refresh='refreshApp' />

      <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <!--Left Col-->
        <div class="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
          <img class="w-full md:w-4/5  z-40" src="/images/logo.svg" />


        </div>
        <!--Right Col-->
        <div class="w-full md:w-3/5 py-6 text-center">

          <h1 class="my-4 text-5xl font-bold leading-tight">
          </h1>
          <p class="leading-normal text-3xl md:text-4xl mb-8">
            Our Premium Service
          </p>
          <p class="leading-normal text-xl mb-8 text-gray-200">
            We are constantly evolving with new services to meet our customer's needs
          </p>




        
        </div>
      </div>
    </div>

    <div class="relative -mt-12 lg:-mt-24">
      <svg viewBox="0 0 1428 174" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g transform="translate(-2.000000, 44.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <path d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496" opacity="0.100000001"></path>
            <path
              d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
              opacity="0.100000001"
            ></path>
            <path d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z" id="Path-4" opacity="0.200000003"></path>
          </g>
          <g transform="translate(-4.000000, 76.000000)" fill="#0f172a" fill-rule="nonzero">
            <path
              d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"
            ></path>
          </g>
        </g>
      </svg>
    </div>
    <section class="bg-gray-900 pt-8">
      <div class="container mx-auto flex flex-wrap pt-4 pb-12">
        <h1 class="w-full my-2 text-3xl md:text-5xl font-bold leading-tight text-center text-yellow-200">
            Project Management
        </h1>
        <div class="w-full mb-4">
          <div class="h-1 mx-auto gradient w-full sm:w-3/4 md:w-1/2 my-0 py-0 rounded-t"></div>
        </div>

      </div>

      <div class="container mx-auto flex flex-wrap md:pt-4 pb-12 justify-center px-10">

        <div class="p-4 flex flex-col" style='width:380px;max-width:380px;'>
          <div class="flex-1 bg-gray-900 rounded-t overflow-hidden shadow">
            <div class="flex items-center justify-center">
                <img src='/images/feature-icon-01.png' class='h-30'>
            </div>
            <div class="flex items-center justify-center font-bold text-xl text-white py-6">
                Safety
            </div>

              <p class="text-gray-400 text-base mb-6 px-8 text-center" style="letter-spacing:-0.1px;">
                The most important thing is to remain safe. Our routine inspections are digitally interactive and recorded
              </p>
          </div>
        </div>


        <div class="p-4 flex flex-col" style='width:380px;max-width:380px;'>
          <div class="flex-1 bg-gray-900 rounded-t overflow-hidden shadow">
            <div class="flex items-center justify-center">
                <img src='/images/feature-icon-02.png' class='h-30'>
            </div>
            <div class="flex items-center justify-center font-bold text-xl text-white py-6">
                Estimating
            </div>

              <p class="text-gray-400 text-base mb-6 px-8 text-center"  style="letter-spacing:-0.1px;">
                Our estimation process and tools will ensure your project starts on the right track
              </p>
          </div>
        </div>

        <div class="p-4 flex flex-col" style='width:380px;max-width:380px;'>
          <div class="flex-1 bg-gray-900 rounded-t overflow-hidden shadow">
            <div class="flex items-center justify-center">
                <img src='/images/feature-icon-03.png' class='h-30'>
            </div>
            <div class="flex items-center justify-center font-bold text-xl text-white py-6">
                Material Forecasting
            </div>

              <p class="text-gray-400 text-base mb-6 px-8 text-center"  style="letter-spacing:-0.1px;">
                Our intelligent software analyzes supply and demand so that we can satisfy future requirements
              </p>
          </div>
        </div>




        <div class="p-4 flex flex-col" style='width:380px;max-width:380px;'>
          <div class="flex-1 bg-gray-900 rounded-t overflow-hidden shadow">
            <div class="flex items-center justify-center">
                <img src='/images/feature-icon-04.png' class='h-30'>
            </div>
            <div class="flex items-center justify-center font-bold text-xl text-white py-6">
                Costing
            </div>

              <p class="text-gray-400 text-base mb-6 px-8 text-center"  style="letter-spacing:-0.1px;">
                You can expect accurate job costing when all aspects of our projects are digitally tracked
              </p>
          </div>
        </div>



        <div class="p-4 flex flex-col" style='width:380px;max-width:380px;'>
          <div class="flex-1 bg-gray-900 rounded-t overflow-hidden shadow">
            <div class="flex items-center justify-center">
                <img src='/images/feature-icon-05.png' class='h-30'>
            </div>
            <div class="flex items-center justify-center font-bold text-xl text-white py-6">
                3D Design
            </div>

              <p class="text-gray-400 text-base mb-6 px-8 text-center"  style="letter-spacing:-0.1px;">
                Emerse yourself in our beautifully rendered 3D scaffold designs
              </p>
          </div>
        </div>


        <div class="p-4 flex flex-col" style='width:380px;max-width:380px;'>
          <div class="flex-1 bg-gray-900 rounded-t overflow-hidden shadow">
            <div class="flex items-center justify-center">
                <img src='/images/feature-icon-06.png' class='h-30'>
            </div>
            <div class="flex items-center justify-center font-bold text-xl text-white py-6">
                Data Capturing
            </div>

              <p class="text-gray-400 text-base mb-6 px-8 text-center"  style="letter-spacing:-0.1px;">
                Go paperless! All our project processes are digitally recorded and available on-demand
              </p>
          </div>
        </div>


        <div class="p-4 flex flex-col" style='width:380px;max-width:380px;'>
          <div class="flex-1 bg-gray-900 rounded-t overflow-hidden shadow">
            <div class="flex items-center justify-center">
                <img src='/images/feature-icon-01.png' class='h-30'>
            </div>
            <div class="flex items-center justify-center font-bold text-xl text-white py-6">
                Digital Time Sheets
            </div>

              <p class="text-gray-400 text-base mb-6 px-8 text-center"  style="letter-spacing:-0.1px;">
                Hours are recorded, signed and approved on mobile devices and synchronized to our systems. No Internet? no Problem! Time can still be recorded and uploaded later
              </p>
          </div>
        </div>



        <div class="p-4 flex flex-col" style='width:380px;max-width:380px;'>
          <div class="flex-1 bg-gray-900 rounded-t overflow-hidden shadow">
            <div class="flex items-center justify-center">
                <img src='/images/feature-icon-02.png' class='h-30'>
            </div>
            <div class="flex items-center justify-center font-bold text-xl text-white py-6">
                KPI Dashboards
            </div>

              <p class="text-gray-400 text-base mb-6 px-8 text-center"  style="letter-spacing:-0.1px;">
                Our beautiful and vibrant dashboards allow you to keep an eye on KPIs
              </p>
          </div>
        </div>


        <div class="p-4 flex flex-col" style='width:380px;max-width:380px;'>
          <div class="flex-1 bg-gray-900 rounded-t overflow-hidden shadow">
            <div class="flex items-center justify-center">
                <img src='/images/feature-icon-03.png' class='h-30'>
            </div>
            <div class="flex items-center justify-center font-bold text-xl text-white py-6">
                Performance Reports
            </div>

              <p class="text-gray-400 text-base mb-6 px-8 text-center"  style="letter-spacing:-0.1px;">
                We believe in full transparency - monitor the progress and performance of your projects
              </p>
          </div>
        </div>


        <div class="p-4 flex flex-col" style='width:380px;max-width:380px;'>
          <div class="flex-1 bg-gray-900 rounded-t overflow-hidden shadow">
            <div class="flex items-center justify-center">
                <img src='/images/feature-icon-04.png' class='h-30'>
            </div>
            <div class="flex items-center justify-center font-bold text-xl text-white py-6">
                Planning
            </div>

              <p class="text-gray-400 text-base mb-6 px-8 text-center"  style="letter-spacing:-0.1px;">
                We help our customers with every phase of the planning process
              </p>
          </div>
        </div>


        <div class="p-4 flex flex-col" style='width:380px;max-width:380px;'>
          <div class="flex-1 bg-gray-900 rounded-t overflow-hidden shadow">
            <div class="flex items-center justify-center">
                <img src='/images/feature-icon-03.png' class='h-30'>
            </div>
            <div class="flex items-center justify-center font-bold text-xl text-white py-6">
                Software as a Service
            </div>

              <p class="text-gray-400 text-base mb-6 px-8 text-center"  style="letter-spacing:-0.1px;">
                No downloads, no upgrades. We take care of all your software needs
              </p>
          </div>
        </div>


        <div class="p-4 flex flex-col" style='width:380px;max-width:380px;'>
          <div class="flex-1 bg-gray-900 rounded-t overflow-hidden shadow">
            <div class="flex items-center justify-center">
                <img src='/images/feature-icon-03.png' class='h-30'>
            </div>
            <div class="flex items-center justify-center font-bold text-xl text-white py-6">
                Process Driven
            </div>

              <p class="text-gray-400 text-base mb-6 px-8 text-center"  style="letter-spacing:-0.1px;">
                Our consistent processes provide control and strategy for any project
              </p>
          </div>
        </div>



      </div>
    </section>
    <!-- Change the colour #f8fafc to match the previous section colour -->
    <svg class="wave-top" viewBox="0 0 1439 147" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-1.000000, -14.000000)" fill-rule="nonzero">
          <g class="wave" fill="#0f172a">
            <path
              d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"
            ></path>
          </g>
          <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
            <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
              <path d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496" opacity="0.100000001"></path>
              <path
                d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                opacity="0.100000001"
              ></path>
              <path d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z" opacity="0.200000003"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <section class="container mx-auto text-center py-6 mb-12">
      <h1 class="w-full my-2 text-5xl font-bold leading-tight text-center text-white">
        Contact Us!
      </h1>
      <div class="w-full mb-4">
        <div class="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
      </div>
      <h3 class="my-4 text-3xl leading-tight mb-12">
         We're eager to start managing your projects!
      </h3>
      <a href='mailto:info@skywaycanada.ca' class="mx-auto cursor-pointer lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-10 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
        E-mail
      </a>
    </section>
    <!--Footer-->
    <footer class="bg-gray-900">
      <div class="container mx-auto px-8">
        <div class="w-full flex flex-col md:flex-row py-6">
          <div class="flex-1 mb-6 text-black">
            <a class="text-pink-600 no-underline hover:no-underline font-bold text-2xl lg:text-4xl" href="#">
              <!--Icon from: http://www.potlabicons.com/ -->
              <img class="w-full z-40" src="/images/logo.svg" />
            </a>
          </div>
          <div class="flex-1">
          </div>
          <div class="flex-1">
            <p class="uppercase text-yellow-100 md:mb-6">Skyway Canada Limited</p>
            <ul class="list-reset mb-6">

              <li class="mt-2 inline-block mr-16 md:block md:mr-0">
                <span class='inline-block mr-4 relative top-1'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill='#0270D7'><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 16.057v-3.057h2.994c-.059 1.143-.212 2.24-.456 3.279-.823-.12-1.674-.188-2.538-.222zm1.957 2.162c-.499 1.33-1.159 2.497-1.957 3.456v-3.62c.666.028 1.319.081 1.957.164zm-1.957-7.219v-3.015c.868-.034 1.721-.103 2.548-.224.238 1.027.389 2.111.446 3.239h-2.994zm0-5.014v-3.661c.806.969 1.471 2.15 1.971 3.496-.642.084-1.3.137-1.971.165zm2.703-3.267c1.237.496 2.354 1.228 3.29 2.146-.642.234-1.311.442-2.019.607-.344-.992-.775-1.91-1.271-2.753zm-7.241 13.56c-.244-1.039-.398-2.136-.456-3.279h2.994v3.057c-.865.034-1.714.102-2.538.222zm2.538 1.776v3.62c-.798-.959-1.458-2.126-1.957-3.456.638-.083 1.291-.136 1.957-.164zm-2.994-7.055c.057-1.128.207-2.212.446-3.239.827.121 1.68.19 2.548.224v3.015h-2.994zm1.024-5.179c.5-1.346 1.165-2.527 1.97-3.496v3.661c-.671-.028-1.329-.081-1.97-.165zm-2.005-.35c-.708-.165-1.377-.373-2.018-.607.937-.918 2.053-1.65 3.29-2.146-.496.844-.927 1.762-1.272 2.753zm-.549 1.918c-.264 1.151-.434 2.36-.492 3.611h-3.933c.165-1.658.739-3.197 1.617-4.518.88.361 1.816.67 2.808.907zm.009 9.262c-.988.236-1.92.542-2.797.9-.89-1.328-1.471-2.879-1.637-4.551h3.934c.058 1.265.231 2.488.5 3.651zm.553 1.917c.342.976.768 1.881 1.257 2.712-1.223-.49-2.326-1.211-3.256-2.115.636-.229 1.299-.435 1.999-.597zm9.924 0c.7.163 1.362.367 1.999.597-.931.903-2.034 1.625-3.257 2.116.489-.832.915-1.737 1.258-2.713zm.553-1.917c.27-1.163.442-2.386.501-3.651h3.934c-.167 1.672-.748 3.223-1.638 4.551-.877-.358-1.81-.664-2.797-.9zm.501-5.651c-.058-1.251-.229-2.46-.492-3.611.992-.237 1.929-.546 2.809-.907.877 1.321 1.451 2.86 1.616 4.518h-3.933z"/></svg>                  
                </span>
                <a href="https://www.skywaycanada.ca" target="_blank" class="no-underline hover:underline text-gray-400 hover:text-yellow-400">Website</a>
              </li>

              <li class="mt-2 inline-block mr-16 md:block md:mr-0">
                <span class='inline-block mr-4 relative top-1.5'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill='#0270D7'><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg>                
                </span>
                <a href="https://www.linkedin.com/company/skyway-canada-ltd" target="_blank" class="no-underline hover:underline text-gray-400 hover:text-yellow-400">Linked In</a>
              </li>



              <li class="mt-2 inline-block mr-2 md:block md:mr-0">
                <span class='inline-block mr-4 relative top-1.5'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill='#0270D7'><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/></svg>                  
                </span>
                <a href="https://twitter.com/skywaycanada"  target="_blank" class="no-underline hover:underline text-gray-400 hover:text-yellow-400">Twitter</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="text-gray-500">Copyright &#169; Skyway Canada Limited</div>
    </footer>
  </div>

</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import UpdateAvailable from '@/components/UpdateAvailable.vue';

import update from '@/mixins/update';


  export default {
    mixins: [
      update,
    ],

    components: {
        UpdateAvailable,
      },


    mounted() {

      var scrollpos = window.scrollY;
      var header = document.getElementById("header");
      var headerLogo = document.getElementById("header-logo");
      var navcontent = document.getElementById("nav-content");
      var navaction = document.getElementById("navAction");
      var toToggle = document.querySelectorAll(".toggleColor");

      document.addEventListener("scroll", function () {
        /*Apply classes for slide in bar*/
        scrollpos = window.scrollY;

        if (scrollpos > 10) {
          header.classList.add("bg-gray-900");
          headerLogo.classList.remove("hidden");
          navaction.classList.remove("bg-white");
          navaction.classList.add("bg-yellow-300");
          //Use to switch toggleColor colours
          for (var i = 0; i < toToggle.length; i++) {
            toToggle[i].classList.add("text-white");
            toToggle[i].classList.remove("sm:text-black");
          }
          header.classList.add("shadow");
          navcontent.classList.remove("bg-gray-100");
//          navcontent.classList.add("bg-white");
        } else {
          header.classList.remove("bg-gray-900");
          headerLogo.classList.add("hidden");
          navaction.classList.remove("bg-yellow-300");
          navaction.classList.add("bg-white");
          //Use to switch toggleColor colours
          for (var x = 0; x < toToggle.length; x++) {
            toToggle[x].classList.add("sm:text-black");
            toToggle[x].classList.remove("text-white");
          }

          header.classList.remove("shadow");
          navcontent.classList.remove("bg-white");
//          navcontent.classList.add("bg-gray-100");
        }
      });



      var navMenuDiv = document.getElementById("nav-content");
      var navMenu = document.getElementById("nav-toggle");

      document.onclick = check;
      function check(e) {
        var target = (e && e.target) || (event && event.srcElement);

        //Nav Menu
        if (!checkParent(target, navMenuDiv)) {
          // click NOT on the menu
          if (checkParent(target, navMenu)) {
            // click on the link
            if (navMenuDiv.classList.contains("hidden")) {
              navMenuDiv.classList.remove("hidden");
            } else {
              navMenuDiv.classList.add("hidden");
            }
          } else {
            // click both outside link and outside menu, hide menu
            navMenuDiv.classList.add("hidden");
          }
        }
      }
      function checkParent(t, elm) {
        while (t.parentNode) {
          if (t == elm) {
            return true;
          }
          t = t.parentNode;
        }
        return false;
      }      



    },

    computed: {
      ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user',
        connected: 'connection/connected',
      })
      
    },


    methods: {
      ...mapActions({
        signOutAction: 'auth/signOut',
      }),

      async signOut () {
        await this.signOutAction()

        this.$router.replace({ name: 'Home' })
      },

      name() {

        if (localStorage.name)
          return localStorage.name;

        return 'Not Signed In'

      },

    }
  }



</script>


<style scoped>
 .gradient {
        background: linear-gradient(90deg, #0f172a 0%, #ddcc00 100%);
      }
</style>